// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

// @font-face {
//     font-family: "Helvetica";
//     src: url('../../fonts/Helvetica-Bold.ttf') format('ttf');
//     font-weight: 700;
// }
@font-face {
    font-family: "SF Pro Text";
    src: url('../../fonts/sf-pro-text/SFPRODISPLAYBOLD.OTF') ;
    font-weight: 700;
}
@font-face {
    font-family: "SF Pro Text";
    src: url('../../fonts/sf-pro-text/SFPRODISPLAYMEDIUM.OTF') ;
    font-weight: 500;
}

