// ========================================= common start
html,
body {
  font-family: $main-font;
  background-color: $main-bg;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.37;
  color: $accent;
  
  margin: 0 auto;
  width: 100%;

  scroll-behavior: smooth;
  overflow-x: hidden;
}
body.hidden {
  overflow: hidden;
}

section{
  padding: 50px 0;
  @include desktop{
    padding: 80px 0;
  }
}

// ------------------------------ navigation 

.navigation{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @include desktop{
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

// ------------------------------  logo 
.logo{
  font-size: 24px;
  line-height: inherit;
  font-weight: 700;
  color: inherit;
  transition: $transition;

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;

  &:hover, &:focus {
      color: $second;
    }
  
  &:active {
    color: inherit;
  }

}


// ------------------------------ links 

.link{
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding-bottom: 4px;

  font-size: 16px;
  font-weight: 700;
  line-height: inherit;
  color: inherit;


  transition: $transition;
  border-bottom: 2px solid transparent;
  
  &:hover,&:focus{
    color: $second;
    border-bottom: 2px solid $accent;
  }
  &:active{
    color: $accent;
    border-bottom: 2px solid transparent;
  }
}


// --------------------------------- btn
.btn {
  width: 100%;
  max-width: max-content;

  padding: 14px 40px;
  border:none;
  background-color: $accent;
  color: $white;
  font-size: 16px;
  line-height: inherit;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  &-second{
    background-color: $white;
    color: $accent;
  }
}

// ------------------------------ btn hover focus active 
.hfa {
  transition: $transition;
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.0);

  &:hover,
  &:focus {
    background-color: $second;
  }

  &:active {
    background-color: $accent;
    -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
      -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
      box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  }

  &-second{
      &:hover,
      &:focus {
        color: $white;
      }
      &:active {
        background-color: $white;
        color: $accent;
        -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
        -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
        box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
      }
  }
}

// ------------------------------ headings 1  2  3 start

.heading1{
  font-size: 38px;
  font-weight: 700;
  line-height: inherit;
  @include desktop{
    font-size: 42px;
  }
}
.heading2 {
    font-size: 32px;
    font-weight: 700;
    line-height: inherit;
}
.heading3 {
  font-size: 24px;
  font-weight: 700;
  line-height: inherit;

}


// ------------------------------ headings 1  2  3 end



.border-b{
  @include desktop{
  border-bottom: 2px solid $accent;
  }
  
}

//margin

.m-0{
  margin: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-14 {
  margin-bottom: 14px;
}
.mb-16{
  margin-bottom: 16px;
}
.mb-18 {
  margin-bottom: 16px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-24{
  margin-bottom: 24px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-60 {
  margin-bottom: 60px;
}

//  ------------------------------  for form validate

.just-validate-error-field.form-input{
  border:4px solid $error
}

.just-validate-error-label{

  display: none;
}



//  ------------------------------  mobile/tablet/desktop - hidden -start
.header-nav,.upper, img {
  &.mobile-hidden{
    display: none;
    @include tablet{
      display: flex;
    }
  }
}
br, .header-nav,.upper, img{
  &.tablet-hidden {
    @include tablet{
      display: none;
    }
  }
}
.header-nav.tablet-hidden,.upper.tablet-hidden {
  @include desktop {
    display: flex;
  }
}
img, .btn-burger{
  &.desktop-hidden {

      @include desktop {
        display: none;
      }
    }
}

//  ------------------------------  mobile/tablet/desktop - hidden -end

.disc {
  list-style: disc;
  padding-left: 16px;
}

.submit{
    height: calc(100vh - 370px);
      text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

        @include desktop {
            height: calc(100vh - 345px);
          }
      .link{
        margin: 0 auto;
      }
}

// ========================================= common end





