.hero{


    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        gap: 40px;

        @include desktop{
            flex-direction: row;
        }
        
    }

    .text-wrapper{
        max-width: 500px;
    }

    .list{
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        max-width: 430px;
        
        @include tablet{
            align-self: flex-end;
        }

        @include desktop{
            gap:36px;
            align-self: center;
        }

        &__item{
            width: 100%;
            max-width: 179px;
            @include desktop{
                
                max-width: 197px;
            }
        }
    }

}

.consulting{
    padding-top: 0;
    padding-bottom: 0;

    background-color: $accent;
    color: $white;

    .banner{
        position: relative;
        top: 0;
        left: -24px;
        width: 120%;
        max-width: 430px;
        @include tablet{
            max-width: 768px;
        }
        @include desktop{
            max-width: 1200px;
            left:-40px;
        }
    }

    .bottom-wrapper{
        @include desktop{
            display: flex;
            gap: 40px;
        }
    }


    .text-wrapper{
        padding-top: 40px;
        padding-bottom: 40px;
        @include desktop{
            max-width: 520px;
        }
    }

    .bottom-image{
        position: relative;
        top: 0;
        left: -24px;
        width: 120%;
        max-width: 430px;

        @include tablet{
            max-width: 768px;
        }
        @include desktop{
            max-width: 600px;
            left: 0;
        }
    }



}

.about-us{
    .content-wrapper{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.services{

    .content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
    
            gap: 24px;
    
        }

    .list{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 24px;
        color: $white;

        @include tablet{
            flex-direction: row;
            flex-wrap: wrap;
        }
        &__item{
            padding: 32px;
            background-color: $accent;
            width: 100%;
            
            @include tablet{
                max-width: 348px;
            }
            @include desktop{
                max-width: 357px;
            }
        }
    }    
}

.contacts{
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: $accent;
    color: $white;

    .content-wrapper{
        display: flex;
        flex-direction: column-reverse;

        @include desktop{
            flex-direction: row;
        }

    }

    .image{

        position: relative;
        top: 0;
        left: -24px;
        width: 120%;
        max-width: 430px;

        @include tablet {
            max-width: 768px;
        }

        @include desktop {
            max-width: 600px;
            left: -40px;
        }

    }

}
