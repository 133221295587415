// font family
$main-font: "Manrope", sans-serif;
$second-font: "SF Pro Text", sans-serif;


// bg
$main-bg:#fffaf4;
// text

// transition
$transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

$white: #fffaf4;
$black:#121212;
$accent: #0f4023;
$second: #007a31;
$error:#ff4242;

// modal
$modal-bgd: #2C2C2C;


