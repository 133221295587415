.form{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 auto;
    padding-top: 101px;
    padding-bottom: 101px;
    width: 100%;
    max-width: 350px;
    @include tablet{
        max-width: 520px;
    }

    h2{
        text-align: center;
        margin-bottom: 32px;
    }
    
    &-label{
        width: 100%;
    }

    &-input{
        width: 100%;
        padding: 12px 28px;
        background-color: transparent;
        border: 4px solid $white;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 700;
        color: $white;
        transition: $transition;
        outline: none;

        &:hover,&:focus{
            border: 4px solid $second;
        }



        &::placeholder{
            color: $white;
        }
    
    }

    .btn{
        margin-top: 8px;
        max-width: 100%;
    }

}