@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 430px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    padding: 0 40px;
    max-width: 1200px;
  }
}

@font-face {
  font-family: "SF Pro Text";
  src: url("../../fonts/sf-pro-text/SFPRODISPLAYBOLD.OTF");
  font-weight: 700;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../../fonts/sf-pro-text/SFPRODISPLAYMEDIUM.OTF");
  font-weight: 500;
}
.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

li:last-child {
  margin-bottom: 0 !important;
}

svg {
  fill: currentColor;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  font-family: "Manrope", sans-serif;
  background-color: #fffaf4;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.37;
  color: #0f4023;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

section {
  padding: 50px 0;
}
@media screen and (min-width: 1200px) {
  section {
    padding: 80px 0;
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
@media screen and (min-width: 1200px) {
  .navigation {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.logo {
  font-size: 24px;
  line-height: inherit;
  font-weight: 700;
  color: inherit;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
}
.logo:hover, .logo:focus {
  color: #007a31;
}
.logo:active {
  color: inherit;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding-bottom: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: inherit;
  color: inherit;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-bottom: 2px solid transparent;
}
.link:hover, .link:focus {
  color: #007a31;
  border-bottom: 2px solid #0f4023;
}
.link:active {
  color: #0f4023;
  border-bottom: 2px solid transparent;
}

.btn {
  width: 100%;
  max-width: max-content;
  padding: 14px 40px;
  border: none;
  background-color: #0f4023;
  color: #fffaf4;
  font-size: 16px;
  line-height: inherit;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}
.btn-second {
  background-color: #fffaf4;
  color: #0f4023;
}

.hfa {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0);
}
.hfa:hover, .hfa:focus {
  background-color: #007a31;
}
.hfa:active {
  background-color: #0f4023;
  -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
}
.hfa-second:hover, .hfa-second:focus {
  color: #fffaf4;
}
.hfa-second:active {
  background-color: #fffaf4;
  color: #0f4023;
  -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
}

.heading1 {
  font-size: 38px;
  font-weight: 700;
  line-height: inherit;
}
@media screen and (min-width: 1200px) {
  .heading1 {
    font-size: 42px;
  }
}

.heading2 {
  font-size: 32px;
  font-weight: 700;
  line-height: inherit;
}

.heading3 {
  font-size: 24px;
  font-weight: 700;
  line-height: inherit;
}

@media screen and (min-width: 1200px) {
  .border-b {
    border-bottom: 2px solid #0f4023;
  }
}

.m-0 {
  margin: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-18 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.just-validate-error-field.form-input {
  border: 4px solid #ff4242;
}

.just-validate-error-label {
  display: none;
}

.header-nav.mobile-hidden, .upper.mobile-hidden, img.mobile-hidden {
  display: none;
}
@media screen and (min-width: 768px) {
  .header-nav.mobile-hidden, .upper.mobile-hidden, img.mobile-hidden {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  br.tablet-hidden, .header-nav.tablet-hidden, .upper.tablet-hidden, img.tablet-hidden {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .header-nav.tablet-hidden, .upper.tablet-hidden {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  img.desktop-hidden, .btn-burger.desktop-hidden {
    display: none;
  }
}

.disc {
  list-style: disc;
  padding-left: 16px;
}

.submit {
  height: calc(100vh - 370px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1200px) {
  .submit {
    height: calc(100vh - 345px);
  }
}
.submit .link {
  margin: 0 auto;
}

.header {
  padding-top: 24px;
  padding-bottom: 0;
}
.header-nav {
  display: flex;
  gap: 16px;
  align-items: center;
}
.header .list__item {
  padding: 0;
}

.footer {
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .footer {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}
.footer::before {
  content: "";
  position: absolute;
  top: 140px;
  width: 100%;
  height: 2px;
  background-color: #0f4023;
}
@media screen and (min-width: 1200px) {
  .footer::before {
    display: none;
  }
}
.footer .navigation {
  gap: 24px;
}
.footer .logo {
  text-align: center;
}
.footer .heading4 {
  text-align: center;
}
.footer-contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .footer-contacts {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.footer-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.footer .link {
  font-family: "SF Pro Text", sans-serif;
  font-weight: 700;
  color: inherit;
  font-size: 16px;
  line-height: 1.16;
}
.footer .privacy-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  line-height: 1.5;
}
@media screen and (min-width: 1200px) {
  .footer .privacy-list {
    align-items: flex-end;
  }
}
.footer .privacy-link {
  font-family: "SF Pro Text", sans-serif;
}
.footer .bottom-wrapper {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
@media screen and (min-width: 1200px) {
  .footer .bottom-wrapper {
    padding-top: 24px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.footer .list-social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.footer .rights {
  max-width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
@media screen and (min-width: 1200px) {
  .footer .rights {
    text-align: start;
  }
}

.hero .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}
@media screen and (min-width: 1200px) {
  .hero .content-wrapper {
    flex-direction: row;
  }
}
.hero .text-wrapper {
  max-width: 500px;
}
.hero .list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 430px;
}
@media screen and (min-width: 768px) {
  .hero .list {
    align-self: flex-end;
  }
}
@media screen and (min-width: 1200px) {
  .hero .list {
    gap: 36px;
    align-self: center;
  }
}
.hero .list__item {
  width: 100%;
  max-width: 179px;
}
@media screen and (min-width: 1200px) {
  .hero .list__item {
    max-width: 197px;
  }
}

.consulting {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #0f4023;
  color: #fffaf4;
}
.consulting .banner {
  position: relative;
  top: 0;
  left: -24px;
  width: 120%;
  max-width: 430px;
}
@media screen and (min-width: 768px) {
  .consulting .banner {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .consulting .banner {
    max-width: 1200px;
    left: -40px;
  }
}
@media screen and (min-width: 1200px) {
  .consulting .bottom-wrapper {
    display: flex;
    gap: 40px;
  }
}
.consulting .text-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (min-width: 1200px) {
  .consulting .text-wrapper {
    max-width: 520px;
  }
}
.consulting .bottom-image {
  position: relative;
  top: 0;
  left: -24px;
  width: 120%;
  max-width: 430px;
}
@media screen and (min-width: 768px) {
  .consulting .bottom-image {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .consulting .bottom-image {
    max-width: 600px;
    left: 0;
  }
}

.about-us .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.services .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}
.services .list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  color: #fffaf4;
}
@media screen and (min-width: 768px) {
  .services .list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.services .list__item {
  padding: 32px;
  background-color: #0f4023;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .services .list__item {
    max-width: 348px;
  }
}
@media screen and (min-width: 1200px) {
  .services .list__item {
    max-width: 357px;
  }
}

.contacts {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #0f4023;
  color: #fffaf4;
}
.contacts .content-wrapper {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 1200px) {
  .contacts .content-wrapper {
    flex-direction: row;
  }
}
.contacts .image {
  position: relative;
  top: 0;
  left: -24px;
  width: 120%;
  max-width: 430px;
}
@media screen and (min-width: 768px) {
  .contacts .image {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .contacts .image {
    max-width: 600px;
    left: -40px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
  padding-top: 101px;
  padding-bottom: 101px;
  width: 100%;
  max-width: 350px;
}
@media screen and (min-width: 768px) {
  .form {
    max-width: 520px;
  }
}
.form h2 {
  text-align: center;
  margin-bottom: 32px;
}
.form-label {
  width: 100%;
}
.form-input {
  width: 100%;
  padding: 12px 28px;
  background-color: transparent;
  border: 4px solid #fffaf4;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 700;
  color: #fffaf4;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  outline: none;
}
.form-input:hover, .form-input:focus {
  border: 4px solid #007a31;
}
.form-input::placeholder {
  color: #fffaf4;
}
.form .btn {
  margin-top: 8px;
  max-width: 100%;
}