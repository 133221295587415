.header{
    padding-top:24px ;
    padding-bottom: 0;
    
    &-nav{
        display: flex;
        gap: 16px;
        align-items: center;
    }

    .list__item{
        padding: 0;
    }
}


