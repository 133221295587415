.footer{

    padding-top: 24px;
    padding-bottom: 24px;
    position: relative;

    @include desktop{
        padding-top: 75px;
        padding-bottom: 75px;
    }

    &::before{
        content: '';
        position: absolute;
        top: 140px;
        width: 100%;
        height: 2px;
        background-color: $accent;
        @include desktop{
            display: none;
        }
    }

    .navigation{
        gap: 24px;

    }

    .logo{
        text-align: center;
    
    }
    .heading4{
        text-align: center;
    }
    &-contacts{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        width: 100%;
        @include desktop{
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &-nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    }

    .link{
        font-family: "SF Pro Text", sans-serif;
        font-weight: 700;
        color: inherit;
        font-size: 16px;
        line-height: 1.16;



    }

    .privacy-list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 14px;
        line-height: 1.5;

        @include desktop{
            align-items: flex-end;
        }
    }

    .privacy-link{
        font-family: $second-font;
    }


    .bottom-wrapper{
        padding-top: 48px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        @include desktop{
            padding-top: 24px;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    .list-social{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

    }


   .rights{
    max-width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    @include desktop{
        text-align: start;
    }
   }     
}
    

